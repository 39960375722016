import Vue from 'vue'
import '@/assets/css/index.css';
import Form1 from './Form_1.vue'
Vue.config.productionTip = false

var e = document.getElementsByClassName('form');
console.log(e);

Array.prototype.forEach.call(e, function(el) {
  new Vue({
    render: function(createElement) {
      let id = this.$el.getAttribute('id')
      let title = this.$el.getAttribute('title')
      let read = this.$el.getAttribute('read')
      return createElement(Form1,
        {
          props: {
            id:id,
            title:title,
            read:read,
          }
        })
    }
  }).$mount(el)
});


